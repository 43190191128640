import { Controller } from "react-hook-form";
import Page from "@components/Layouts/Page";
import Content from "@components/Layouts/Content";
import Form from "@components/Form";
import Switch from "@components/Form/Fields/Switch";
import InputComponent, { Input } from "@components/Form/Fields/Input";
import Select from "@components/Form/Fields/Select";
import FileField from "@components/Form/Fields/AvatarCrop";
import Button from "@components/Button";
import { challengeSchema } from "@utils/validation/challenge";
import styles from "./index.module.css";
import Label from "@components/Form/Label";

const challengeTypeOptions = [
  { value: "check_in", name: "Check in" },
  { value: "books_left", name: "Books left" },
];

const LogoField = ({ name, fileFieldName, ...props }) => (
  <Controller
    name={name}
    render={({ field }) => (
      <>
        <FileField
          name={fileFieldName}
          {...props}
          aspect={1}
          value={field.value}
          onCrop={(img) => {
            field.onChange(img);
          }}
        />
        <Input {...field} {...props} className="hidden" />
      </>
    )}
  />
);
const ChallengeFormPage = ({ form, page, challenge }) => (
  <Form validationSchema={challengeSchema} {...form}>
    <Page
      {...page}
      right={
        <Button medium type="submit">
          Save
        </Button>
      }
    >
      <Content title="Challenge Information">
        <div className={styles.content}>
          <div className={`${styles.center} ${styles.start}`}>
            <LogoField
              fileFieldName="file"
              name="image"
              label="Image"
              className={styles.file}
              dropzoneClassName={styles.fileDropzone}
            />
          </div>
          <div>
            <InputComponent
              name="name"
              placeholder="Enter challenge name"
              label="Name"
            />
            <InputComponent
              name="amount"
              placeholder="Enter challenge amount"
              label="Amount"
            />
            <Select
              name="challenge_type"
              options={challengeTypeOptions}
              label="Challenge type"
            />
            <Switch label="Active" name="active" className={styles.switch} />
            <InputComponent
              name="help_text"
              placeholder="Enter Help Text"
              label="Help Text"
            />
            <div className={styles.content}>
              <Label>
                <div>{"Locked Image"}</div>
              </Label>
              <LogoField
                fileFieldName="locked_image"
                name="locked_image"
                label="Locked Image"
                className={`${styles.fileDropzoneWidth}`}
                dropzoneClassName={styles.fileDropzone}
              />
            </div>
          </div>
        </div>
      </Content>
    </Page>
  </Form>
);

export default ChallengeFormPage;
