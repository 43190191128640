import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from "react-mixpanel";
mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
const initialPath = window.location.href;
const valuesToCheck = [
  "ourmap",
  "sandmap.littlefreelibrary.org",
  "map.littlefreelibrary.org",
];

ReactDOM.render(
  <React.StrictMode>
    <MixpanelProvider mixpanel={mixpanel}>
      <App isMap={valuesToCheck.some((value) => initialPath.includes(value))} />
    </MixpanelProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
