import ChallengeFormPage from "@components/Challenge/Form";
import Request from "@containers/Request";
import withParams from "@containers/withParams";
import withPageTitle from "@containers/withPageTitle";
import { updateChallenge } from "@utils/api/challenge";

const makeChallengeDefaultValues = ({
  image_url,
  locked_image_url,
  ...challenge
}) => ({
  ...challenge,
  image: image_url || "",
  locked_image: locked_image_url || "",
});

const ChallengeUpdatePage = withParams(({ params: { id } }) => {
  const url = `/challenges/${id}.json`;
  return (
    <Request url={url}>
      {(challenge, makeRequest, updateData) => (
        <ChallengeFormPage
          form={{
            url,
            onSubmit: updateChallenge,
            defaultValues: makeChallengeDefaultValues(challenge),
            toast: "Challenge updated",
          }}
          page={{
            title: challenge.name,
            back: true,
          }}
        />
      )}
    </Request>
  );
});

export default withPageTitle(ChallengeUpdatePage, "Update Challenge");
