import 'react-datepicker/dist/react-datepicker.css'
import Highcharts from 'highcharts'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import ReactHighChart from 'highcharts-react-official'

const UsersChart = ({ count, types = null, context, isDataLabelEnabled = true }) => {

	const xAxis = () => {
		if(types){
			const tempArray = []
			Object.keys(types).map((key) => {
				tempArray.push(key)
			})
			return tempArray
		}
	};

	const series = () => {
		if(types &&  Object.keys(types).length > 0 ){
			const tempArray = []
			const finalArray = []
			Object.keys(types[Object.keys(types)[0]]).map((subKey) => {
				tempArray.push(subKey)
			})
			if (tempArray.length > 0) {
				let lastAdd;
				for (let key of tempArray) {
					if(key == 'count' || key == 'Count' || key == 'Total' ){
						lastAdd = key;
					}
					else{
						finalArray.push(
							{
								name: key,
								data: Object.keys(types).map((subKey) => {
									return { name: key, y: Number.parseFloat(types[subKey][key]) }
								}),
							}
						)
					}
				}
				if(lastAdd){
					finalArray.unshift(
						{
							name: lastAdd,
							data: Object.keys(types).map((subKey) => {
								return { name: lastAdd, y: types[subKey][lastAdd] }
							}),
						}
					)
				}
				
			}
			else {
				finalArray.push(
					{
						name: 'count',
						data: Object.keys(types).map((key) => {
							return { name: 'count', y: types[key] }
						}),
					}
				)
			}
	
			return finalArray
		}
	};

	const options = {
		chart: {
			type: 'column'
		},
		title: {
			text: count === 0 ? `${context}` : `Total ${context} : ${count}`
		},
		plotOptions: {
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: isDataLabelEnabled
				}
			}
		},
		xAxis: {
			categories: xAxis(),
			crosshair: true
		},
		yAxis: {
			min: 0,
			stackLabels: {
				enabled: true,
				style: {
					fontWeight: 'bold',
					color: 'gray',
					textOutline: 'none'
				}
			}
		},
		series: series(),
		lang: {
			noData: 'Nothing to show',
		}
	};
	NoDataToDisplay(Highcharts);

	return (
		<ReactHighChart
			highcharts={Highcharts}
			options={options}
		/>
	)
}

export default UsersChart;
