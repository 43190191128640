import { useEffect } from "react";
import {
  unstable_HistoryRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "@components/Layouts/App";
import PublicLayout from "@components/Layouts/Public";
import ErrorBoundary from "@components/ErrorBoundary";
import Login from "@components/Public/Login";
import AccountPage from "@components/Public/Account";
import RegistrationPage from "@components/Public/Registration";
import WelcomePage from "@components/Public/WelcomePage";
import PasswordForgotPage from "@components/Public/ForgotPassword";
import PasswordUpdatePage from "@components/Public/UpdatePassword";
import InvitationPage from "@components/Public/InvitationPage";
import ConfirmationPage from "@components/Public/ConfirmationPage";
import UserUpdatePage from "@components/User/Update";
import UserViewPage from "@components/User/View";
import UsersPage from "@components/User/ListPage";
import LibraryCreatePage from "@components/Library/Create";
import LibraryUpdatePage from "@components/Library/Update";
import LibraryLayout from "@components/Library/Layout";
import LibrariesPage from "@components/Library/ListPage";
import LibraryCommentsPage from "@components/Library/CommentsPage";
import StewardLibrariesPage from "@components/Steward/LibrariesPage";
import StewardResourcesPage from "@components/Steward/ResourcesPage";
import LibraryIssuesPage from "@components/Library/IssuesPage";
import LibraryMapPage from "@components/Library/MapPage";
import LibraryPhotosPage from "@components/Photos/Update";
import LibraryTransfersPage from "@components/LibraryTransfer/ListPage";
import LibraryTransferPage from "@components/Library/Transfer";
import ChartersPage from "@components/Charter/ListPage";
import SponsorsCreatePage from "@components/Sponsor/Create";
import SponsorsUpdatePage from "@components/Sponsor/Update";
import SponsorsPage from "@components/Sponsor/ListPage";
import ChallengesCreatePage from "@components/Challenge/Create";
import ChallengesUpdatePage from "@components/Challenge/Update";
import ChallengesPage from "@components/Challenge/ListPage";
import IssuesPage from "@components/Issue/AdminList";
import AnalyticsPage from "@components/Analytic/ListPage";
import AnnouncementsPage from "@components/Announcement/ListPage";
import AnnouncementUpdatePage from "@components/Announcement/Update";
import AnnouncementCreatePage from "@components/Announcement/Create";
//import CheckInsPage from '@components/CheckIn/ListPage';
import GetAppPage from "@components/Public/GetAppPage";
import EmailCodePage from "@components/Public/EmailCodePage";
import DownloadAppPage from "@components/Public/DownloadAppPage";
import IndicatorsCreatePage from "@components/Indicator/Create";
import IndicatorsUpdatePage from "@components/Indicator/Update";
import IndicatorPage from "@components/Indicator/ListPage";
import MapYourLibraryFormPage from "@components/Public/MapYourLibrary/Form";
import TransferYourLibraryRedirectPage from "@components/Public/TransferYourLibraryRedirectPage";
import MapYourLibrarySuccessPage from "@components/Public/MapYourLibrary/Success";

import DonationMessagesCreatePage from "@components/DonationMessages/Create";
import DonationMessagesPage from "@components/DonationMessages/ListPage";
import DonationMessagesUpdatePage from "@components/DonationMessages/Update";

import { MapYourLibraryLoginPage } from "@components/Public/Login";
import MapYourLibraryWelcomePage from "@components/Public/MapYourLibrary/Beginning";
import { LoaderProvider } from "@containers/Loader";
import { ToastProvider } from "@containers/ToastContext";
import { AppInfoProvider } from "@containers/AppInfo";
import { ErrorModalProvider } from "@containers/ErrorModal";
import {
  useAuthState,
  AuthProvider,
  logoutAction,
  useAuthDispatch,
} from "@containers/Auth";
import history from "@utils/history";

const AutoLogout = () => {
  const dispatch = useAuthDispatch();
  useEffect(() => dispatch(logoutAction()), []);
  return null;
};

const Pages = () => {
  const user = useAuthState();
  if (user) {
    if (user.is_steward) {
      return (
        <Layout>
          <Routes>
            <Route path="/ourmap" element={<LibraryMapPage />} />
            <Route
              path="/mapyourlibrary/:email_code/success"
              element={<MapYourLibrarySuccessPage />}
            />
            <Route path="/new-library" element={<AutoLogout />} />
            <Route path="/users/invitation/accept" element={<AutoLogout />} />
            <Route path="/users/confirmation" element={<AutoLogout />} />
            <Route path="/users/password/edit" element={<AutoLogout />} />
            <Route
              path="/libraries/:libraryId"
              element={<LibraryLayout is_steward={user.is_steward} />}
            >
              <Route path="comments" element={<LibraryCommentsPage />} />
              {/*<Route path="check-ins" element={<CheckInsPage />} />*/}
              <Route path="issues" element={<LibraryIssuesPage />} />
              <Route path="edit" element={<LibraryUpdatePage />} />
              <Route path="photos" element={<LibraryPhotosPage />} />
              <Route path="transfer" element={<LibraryTransferPage />} />
              <Route path="" element={<Navigate to="edit" />} />
            </Route>
            <Route path="/libraries" element={<StewardLibrariesPage />} />
            <Route
              path="/steward-resources"
              element={<StewardResourcesPage />}
            />
            <Route path="/account" element={<AccountPage />} />
            <Route path="*" element={<Navigate to="/libraries" />} />
          </Routes>
        </Layout>
      );
    }
    if (user.is_admin) {
      return (
        <Layout>
          <Routes>
            <Route path="/ourmap" element={<LibraryMapPage />} />
            <Route
              path="/mapyourlibrary/:email_code/success"
              element={<MapYourLibrarySuccessPage />}
            />
            <Route path="/transferyourlibrary" element={<AutoLogout />} />
            <Route path="/new-library" element={<AutoLogout />} />
            <Route path="/issues" element={<Outlet />}>
              <Route path="/issues" element={<IssuesPage />} />
              <Route path="/issues/users/:id" element={<UserUpdatePage />} />
              <Route
                path="/issues/libraries/:libraryId"
                element={<LibraryLayout back />}
              >
                <Route path="comments" element={<LibraryCommentsPage />} />
                {/*<Route path="check-ins" element={<CheckInsPage />} />*/}
                <Route path="issues" element={<LibraryIssuesPage />} />
                <Route path="edit" element={<LibraryUpdatePage />} />
                <Route path="photos" element={<LibraryPhotosPage />} />
                <Route path="transfer" element={<LibraryTransferPage />} />
                {/* <Route path="/" element={<Navigate to="edit" />} /> */}
              </Route>
            </Route>

            <Route path="sponsors" element={<Outlet />}>
              <Route path="/sponsors/new" element={<SponsorsCreatePage />} />
              <Route path="/sponsors/:id" element={<SponsorsUpdatePage />} />
              <Route path="/sponsors" element={<SponsorsPage />} />
            </Route>
            <Route path="challenges" element={<Outlet />}>
              <Route
                path="/challenges/new"
                element={<ChallengesCreatePage />}
              />
              <Route
                path="/challenges/:id"
                element={<ChallengesUpdatePage />}
              />
              <Route path="/challenges" element={<ChallengesPage />} />
            </Route>
            <Route path="/users" element={<Outlet />}>
              <Route path="/users" element={<UsersPage />} />
              <Route path="/users/:id/edit" element={<UserUpdatePage />} />
              <Route path="/users/:id" element={<UserViewPage />} />
              <Route path="/users/invitation/accept" element={<AutoLogout />} />
              <Route path="/users/confirmation" element={<AutoLogout />} />
              <Route path="/users/password/edit" element={<AutoLogout />} />
            </Route>
            <Route path="/libraries" element={<Outlet />}>
              <Route path="/libraries" element={<LibrariesPage />} />
              <Route path="/libraries/:libraryId" element={<LibraryLayout />}>
                <Route path="comments" element={<LibraryCommentsPage />} />
                {/*<Route path="check-ins" element={<CheckInsPage />} />*/}
                <Route path="issues" element={<LibraryIssuesPage />} />
                <Route path="photos" element={<LibraryPhotosPage />} />
                <Route path="transfer" element={<LibraryTransferPage />} />
                <Route path="edit" element={<LibraryUpdatePage />} />
                <Route path="" element={<Navigate to="edit" />} />
              </Route>
            </Route>

            <Route path="/charters" element={<ChartersPage />} />
            <Route path="/transfers" element={<Outlet />}>
              <Route path="/transfers" element={<LibraryTransfersPage />} />
              <Route path="/transfers/users/:id" element={<UserUpdatePage />} />
              <Route
                path="/transfers/libraries/:libraryId"
                element={<LibraryLayout back />}
              >
                <Route path="comments" element={<LibraryCommentsPage />} />
                {/*<Route path="check-ins" element={<CheckInsPage />} />*/}
                <Route path="issues" element={<LibraryIssuesPage />} />
                <Route path="edit" element={<LibraryUpdatePage />} />
                <Route path="photos" element={<LibraryPhotosPage />} />
                <Route path="transfer" element={<LibraryTransferPage />} />
                {/* <Route path="/" element={<Navigate to="edit" />} /> */}
              </Route>
            </Route>
            <Route path="/indicators" element={<Outlet />}>
              <Route path="/indicators" element={<IndicatorPage />} />
              <Route
                path="/indicators/new"
                element={<IndicatorsCreatePage />}
              />
              <Route
                path="/indicators/:id"
                element={<IndicatorsUpdatePage />}
              />
            </Route>

            <Route path="announcements" element={<Outlet />}>
              <Route
                path="/announcements/new"
                element={<AnnouncementCreatePage />}
              />
              <Route
                path="/announcements/:id"
                element={<AnnouncementUpdatePage />}
              />
              <Route path="/announcements" element={<AnnouncementsPage />} />
            </Route>

            <Route path="/analytics" element={<AnalyticsPage />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/donationMessages" element={<Outlet />}>
              <Route
                path="/donationMessages"
                element={<DonationMessagesPage />}
              />
              <Route
                path="/donationMessages/new"
                element={<DonationMessagesCreatePage />}
              />
              <Route
                path="/donationMessages/:id"
                element={<DonationMessagesUpdatePage />}
              />
            </Route>
            <Route path="*" element={<Navigate to="/users" />} />
            <Route
              path="/steward-resources"
              element={<StewardResourcesPage />}
            />
          </Routes>
        </Layout>
      );
    }
    return (
      <PublicLayout>
        <Routes>
          <Route path="/ourmap" element={<LibraryMapPage />} />
          <Route
            path="/mapyourlibrary/:email_code/success"
            element={<MapYourLibrarySuccessPage />}
          />
          <Route path="/transferyourlibrary" element={<AutoLogout />} />
          <Route path="/new-library" element={<AutoLogout />} />
          <Route path="/users/invitation/accept" element={<AutoLogout />} />
          <Route path="/users/confirmation" element={<AutoLogout />} />
          <Route path="/users/password/edit" element={<AutoLogout />} />
          <Route path="*" element={<GetAppPage logout />} />
        </Routes>
      </PublicLayout>
    );
  }
  return (
    <PublicLayout>
      <Routes>
        <Route path="/ourmap" element={<LibraryMapPage />} />
        <Route path="/get-app" element={<GetAppPage />} />
        <Route path="/download-app" element={<DownloadAppPage />} />
        <Route
          path="/mapyourlibrary/success"
          element={<MapYourLibrarySuccessPage />}
        />
        <Route
          path="/mapyourlibrary/:email_code/password/forgot"
          element={<PasswordForgotPage />}
        />
        <Route
          path="/mapyourlibrary/:email_code/success"
          element={<MapYourLibrarySuccessPage />}
        />
        <Route
          path="/mapyourlibrary/:email_code/login"
          element={<MapYourLibraryLoginPage />}
        />
        <Route
          path="/mapyourlibrary/:email_code"
          element={<MapYourLibraryWelcomePage />}
        />
        <Route
          path="/transferyourlibrary"
          element={<TransferYourLibraryRedirectPage />}
        />
        <Route path="/new-library" element={<EmailCodePage />} />
        <Route path="/users/invitation/accept" element={<InvitationPage />} />
        <Route path="/users/confirmation" element={<ConfirmationPage />} />
        <Route path="/password/forgot" element={<PasswordForgotPage />} />
        <Route path="/users/password/edit" element={<PasswordUpdatePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/" exact element={<WelcomePage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </PublicLayout>
  );
};

const App = ({ isMap }) => (
  <Router history={history}>
    <Helmet
      titleTemplate="%s | Little Free Library"
      defaultTitle="Little Free Library"
    >
      <meta
        name="description"
        content={
          isMap
            ? "Little Free Library Web Map"
            : "Little Free Library Dashboard"
        }
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <ErrorBoundary text="There was a problem please refresh the browser">
      <ErrorModalProvider>
        <ToastProvider>
          <LoaderProvider>
            <AuthProvider>
              <AppInfoProvider>
                {isMap ? <LibraryMapPage /> : <Pages />}
              </AppInfoProvider>
            </AuthProvider>
          </LoaderProvider>
        </ToastProvider>
      </ErrorModalProvider>
    </ErrorBoundary>
  </Router>
);

export default App;
