import { Controller } from "react-hook-form";
import Page from "@components/Layouts/Page";
import Content from "@components/Layouts/Content";
import Form from "@components/Form";
import Switch from "@components/Form/Fields/Switch";
import InputComponent, { Input } from "@components/Form/Fields/Input";
import FileField from "@components/Form/Fields/AvatarCrop";
import Button from "@components/Button";
import { sponsorSchema } from "@utils/validation/sponsor";
import styles from "./index.module.css";

const LogoField = ({ name, ...props }) => (
  <Controller
    name={name}
    render={({ field }) => (
      <>
        <FileField
          name="file"
          {...props}
          aspect={1}
          value={field.value}
          onCrop={(img) => {
            field.onChange(img);
          }}
        />
        <Input {...field} {...props} className="hidden" />
      </>
    )}
  />
);
const SponsorFormPage = ({ form, page, sponsor }) => (
  <Form validationSchema={sponsorSchema} {...form}>
    <Page
      {...page}
      right={
        <Button medium type="submit">
          Save
        </Button>
      }
    >
      <Content title="Sponsor Information">
        <div className={styles.content}>
          <div className={styles.center}>
            <LogoField
              name="image"
              label="Image"
              className={styles.file}
              dropzoneClassName={styles.fileDropzone}
            />
          </div>
          <div>
            <InputComponent
              name="SponsorName__c"
              placeholder="Enter Sponsor Name"
              label="Name"
            />
            <InputComponent
              type="textarea"
              name="SponsorMessage__c"
              placeholder="Enter Sponsor Message"
              label="Message"
            />
            <InputComponent
              name="Link__c"
              placeholder="Enter Sponsor Link"
              label="Sponsor Link"
            />
            <Switch label="Active" name="Active__c" className={styles.switch} />
          </div>
        </div>
      </Content>
    </Page>
  </Form>
);

export default SponsorFormPage;
